import axios from '@plugins/axios';

class TripletaService {
    reportePorFiguraConsolidado(filters = {}, options = {}) {
        if (Object.hasOwnProperty.call(filters, 'consolidado')) {
            filters['consolidado'] = filters['consolidado'] ? 1 : 0;         
        }

        return axios({
            url: `reporte/venta/game/tripleta/figura/consolidado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }

    reportePorFiguraDetallado(filters = {}, options = {}) {
        if (Object.hasOwnProperty.call(filters, 'consolidado')) {
            filters['consolidado'] = filters['consolidado'] ? 1 : 0;         
        }

        return axios({
            url: `reporte/venta/game/tripleta/figura/detallado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
}

export const BaseTripletaService = TripletaService

export default new TripletaService();