var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('v-card',[_c('v-card-title',[_vm._v(" Ventas Por Figura ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('span',{staticClass:"mr-4"},[_vm._v("DÍA")]),_c('Totalizador',{attrs:{"loading":_vm.loadingDaySummary,"summary":_vm.daySummary}})],1),(_vm.params.juego_sorteo_id && _vm.weekSummary)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-4"},[_vm._v("SEMANA")]),_c('Totalizador',{attrs:{"loading":_vm.loadingWeekSummary,"summary":_vm.weekSummary}})],1):_vm._e()])],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.loading)?[_c('thead',[_c('tr',[_c('th',[_vm._v("Comercio")]),_c('th',[_vm._v("Tickets")]),_c('th',[_vm._v("1 Acierto")]),_c('th',[_vm._v("2 Aciertos")]),_c('th',[_vm._v("Ganadores")]),_c('th',[_vm._v("Comision")]),_c('th',[_vm._v("Proyección")]),_c('th',[_vm._v("Total")]),_c('th')])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"9"}},[_vm._v("Cargando...")])])])]:[_c('thead',[_c('tr',[_c('th',[_vm._v("Numero")]),_c('th',[_vm._v("Tickets")]),_c('th',[_vm._v("1 Acierto")]),_c('th',[_vm._v("2 Aciertos")]),_c('th',[_vm._v("Ganadores")]),_c('th',[_vm._v("Comision")]),_c('th',[_vm._v("Proyección")]),_c('th',[_vm._v("Total")]),_c('th')])]),_c('tbody',_vm._l((_vm.list),function(report,index){return _c('tr',{key:`reporte-figura-tripleta-${index}`},[_c('td',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.open({
                                'comercio': 'reporte.figura',
                                'sistema': 'operadora.sistema.reporte.figura',
                            }[_vm.context], {
                                numero: report.numero
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-eye-outline")]),_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"25"}},[_c('img',{attrs:{"src":report.image,"alt":report.numero}})]),_vm._v(" "+_vm._s(report.numero)+" - "+_vm._s(report.label)+" ")],1)],1),_c('td',[_c('v-chip',{attrs:{"label":"","color":"primary"},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                numero: report.numero
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.venta_total,report.code)))])],1)],1),_c('td',[_c('v-chip',{attrs:{"label":""},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                projected_one_hit: report.numero
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.one_hit_count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.one_hit_venta,report.code)))])],1)],1),_c('td',[_c('v-chip',{attrs:{"label":""},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                projected_two_hit: report.numero
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.two_hit_count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.two_hit_venta,report.code)))])],1)],1),_c('td',[_c('v-chip',{attrs:{"label":"","color":"amber darken-4"},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                projected_three_hit: report.numero
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.winner_count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.winner_venta,report.code)))])],1)],1),_c('td',[_vm._v(_vm._s(_vm._f("currency")(report.comision,report.code)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(report.projected,report.code)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(report.total_projected,report.code)))]),_c('td')])}),0)]]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }