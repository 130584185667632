<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <GrupoCard :grupo="grupo" :loading="loading">
                    <template v-slot:actions>
                        <v-btn color="primary" @click="edit()">
                            <v-icon>mdi-pencil</v-icon> EDITAR
                        </v-btn>
                    </template>
                    <v-tabs v-model="tab" icons-and-text>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-cupos" v-if="grupo && $user.isDomain(['operadora', 'master', 'comercializadora'])">Cupos <v-icon>mdi-account-box</v-icon></v-tab>
                        <!-- <v-tab href="#tab-usuarios" v-if="grupo && $user.isDomain(['operadora', 'master', 'comercializadora', 'grupo'])">Usuarios <v-icon>mdi-account-box</v-icon></v-tab> -->
                    </v-tabs>
                </GrupoCard>
            </v-col>
            <v-col cols="12">
                <v-card elevation="5">
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-cupos" v-if="grupo && $user.isDomain(['operadora', 'master', 'comercializadora'])">
                            <v-card flat>
                                <v-card-text>
                                    <GrupoJuegoCrud :grupo="grupo"/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-usuarios" v-if="grupo && $user.isDomain(['operadora', 'master', 'comercializadora', 'grupo'])">
                            <v-card flat>
                                <v-card-title>
                                    Usuarios
                                </v-card-title>
                                <v-card-text>
                                    <UserCrud comercioType="grupo" :comercioId="grupo.id"/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <GrupoModal :open.sync="isModalOpen" @saved="getGrupo()" :grupo="grupo"/>
    </v-container>
</template>
<script>
import GrupoCard from '@components/entities/comercio/grupo/card';
import GrupoModal from '@components/entities/comercio/grupo/modal';

import GrupoService from '@services/comercio/GrupoService';

import GrupoJuegoCrud from '@components/entities/comercio/grupo/juego/crud';
import UserCrud from '@components/entities/comercio/user/crud';

export default {
    components: {
        GrupoCard,
        GrupoModal,
        GrupoJuegoCrud,
        UserCrud
    },
    data: () => ({
        tab: null,
        grupo: null,
        loading: false,

        isModalOpen: false,
    }),
    created: function () {
        this.getGrupo();
    },
    methods: {
        getGrupo: function () {
            this.loading = true;
            GrupoService
                .get(this.$route.params.id)
                .then(({grupo}) => {
                    this.grupo = grupo;
                    this.$store.dispatch('ui/layout/setTitle', `Grupo ${this.grupo?.name}`);
                })
                .finally(() => this.loading = false);
        },
        edit: function () {
            this.isModalOpen = true;
        },
    },
}
</script>