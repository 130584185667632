<template>
    <div>
        <div class="mb-2">
            <v-card>
                <v-card-title>
                    Ventas Por Figura
                </v-card-title>
                <v-card-text>
                    <div class="d-flex align-center mb-2">
                        <span class="mr-4">DÍA</span>
                        <Totalizador :loading="loadingDaySummary" :summary="daySummary"/>
                    </div>
                    <div class="d-flex align-center" v-if="params.juego_sorteo_id && weekSummary">
                        <span class="mr-4">SEMANA</span>
                        <Totalizador :loading="loadingWeekSummary" :summary="weekSummary"/>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-simple-table>
            <template v-slot:default>
                <template v-if="loading">
                    <thead>
                        <tr>
                            <th>Comercio</th>
                            <th>Tickets</th>
                            <th>1 Acierto</th>
                            <th>2 Aciertos</th>
                            <th>Ganadores</th>
                            <th>Comision</th>
                            <th>Proyección</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="9">Cargando...</td>
                        </tr>
                    </tbody>
                </template>
                <template v-else>
                    <thead>
                        <tr>
                            <th>Numero</th>
                            <th>Tickets</th>
                            <th>1 Acierto</th>
                            <th>2 Aciertos</th>
                            <th>Ganadores</th>
                            <th>Comision</th>
                            <th>Proyección</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(report, index) of list" :key="`reporte-figura-tripleta-${index}`">
                            <td>
                                <v-btn small text @click="open({
                                    'comercio': 'reporte.figura',
                                    'sistema': 'operadora.sistema.reporte.figura',
                                }[context], {
                                    numero: report.numero
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-eye-outline</v-icon>
                                    <v-avatar size="25" class="mr-2">
                                        <img
                                            :src="report.image"
                                            :alt="report.numero"
                                            />
                                    </v-avatar>
                                    {{ report.numero }} - {{ report.label }}
                                </v-btn>
                            </td>
                            <td>
                                <v-chip label color="primary" @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    numero: report.numero
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.count }}</span>
                                    <span class="ml-2">{{ report.venta_total | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip label @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    projected_one_hit: report.numero
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.one_hit_count }}</span>
                                    <span class="ml-2">{{ report.one_hit_venta | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip label @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    projected_two_hit: report.numero
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.two_hit_count }}</span>
                                    <span class="ml-2">{{ report.two_hit_venta | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip label color="amber darken-4" @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    projected_three_hit: report.numero
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.winner_count }}</span>
                                    <span class="ml-2">{{ report.winner_venta | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>{{ report.comision | currency(report.code) }}</td>
                            <td>{{ report.projected | currency(report.code) }}</td>
                            <td>{{ report.total_projected | currency(report.code) }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </template>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import TripletaService from '@services/reporte/game/TripletaService';
import VentaService from '@services/reporte/VentaService';

import Totalizador from '@components/entities/reporte/venta/figura/totalizador';

export default {
    components: {
        Totalizador
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        context: {
            default: 'comercio',
        },
    },
    data: function() { 
        return {
            list: [],
            count: 0,
            loading: true,  
            loadingDaySummary: true,
            daySummary: null,
            loadingWeekSummary: true,
            weekSummary: null,
            ventas: 0,
            comision: 0,
            subtotal: 0,
            premio: 0,
            total: 0,
            total_projected: 0,
            projected: 0,
        }
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        listComputed: function () {
            return this.list.map(report => {
                // let color = 'secondary';
                // if (report.percent_projected > 0.1) color = 'primary';
                // if (report.percent_projected < 0.1 && report.percent_projected > -0.1) color = 'secondary';
                // if (report.percent_projected < -0.1) color = 'error';

                // const sorteoTotal = this.subtotal - (report.projected);
                // const sorteoPercent = sorteoTotal / this.subtotal;

                // let sorteoColor = 'secondary';
                // if (sorteoPercent > 0.1) sorteoColor = 'primary';
                // if (sorteoPercent < 0.1 && sorteoPercent > -0.1) sorteoColor = 'secondary';
                // if (sorteoPercent < -0.1) sorteoColor = 'error';

                // let premioColor = 'secondary';
                // let premioPercent = 0;
                // if (report.projected) {
                //     premioPercent = report.projected / this.ventas;
                //     if (premioPercent <= 0) premioColor = 'primary';
                //     else premioColor = 'error';
                // }

                // let dayColor = 'secondary';
                // let dayTotal = 0;
                // let dayPercent = 0;

                // if (this.daySummary) {
                //     dayTotal = this.daySummary.total - (report.total_projected);
                //     dayPercent = this.daySummary.total / dayTotal;

                //     if (dayPercent > 0.1) dayColor = 'primary';
                //     if (dayPercent < 0.1 && dayPercent > -0.1) dayColor = 'secondary';
                //     if (dayPercent < -0.1) dayColor = 'error';
                // }

                // let weekColor = 'secondary';
                // let weekTotal = 0;
                // let weekPercent = 0;

                // if (this.weekSummary) {
                //     weekTotal = this.weekSummary.total - (report.total_projected);
                //     weekPercent = this.weekSummary.total / weekTotal;

                //     if (weekPercent > 0.1) weekColor = 'primary';
                //     if (weekPercent < 0.1 && weekPercent > -0.1) weekColor = 'secondary';
                //     if (weekPercent < -0.1) weekColor = 'error';
                // }

                return {
                    ...report,
                    // color,
                    // sorteoColor,
                    // sorteoTotal,
                    // sorteoPercent,
                    // premioColor,
                    // premioPercent,
                    // dayColor,
                    // dayTotal,
                    // dayPercent,
                    // weekColor,
                    // weekTotal,
                    // weekPercent,
                }
            })
        },
        summarySorteo: function () {
            return {
                venta: this.ventas,
                comision: this.comision,
                premio: this.premio,
                total: this.total,
            }
        }
    },
    methods: {
        getLegibleDistanciaFecha: function (date) {
            if (!date) return 0;

            const start = this.$luxon.fromSQL(date);
            const end = this.$luxon.now();

            const days = Math.floor(end.diff(start, 'days').toObject().days);

            return days;
        },
        getList: function (params = {}) {
            this.list = [];
            this.loading = true;
            this.ventas = 0;
            this.comision = 0;
            this.premio = 0;
            this.total = 0;
            this.subtotal = 0;

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            TripletaService.reportePorFiguraConsolidado({...this.params, ...params, divisa_id: this.divisa.id})
                .then(({reportList}) => {
                    reportList.forEach(reporte => {
                        this.ventas += reporte.venta;
                        this.comision += reporte.comision;
                        this.premio += reporte.premio;
                        this.projected += reporte.projected;
                        this.total += reporte.total;
                        this.total_projected += reporte.total_projected;
                    });
                    this.subtotal = this.ventas - this.comision;
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getDaySummary: function (params = {}) {
            this.daySummary = null;
            this.loadingDaySummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};

            delete parameters.juego_sorteo_id;

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.daySummary = reportList[0];
                }).finally(() => {
                    this.loadingDaySummary = false;
                });
        },
        getWeekSummary: function (params = {}) {
            this.weekSummary = null;
            this.loadingWeekSummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};
            
            const dateParam = parameters.date;
            delete parameters.date;
            delete parameters.juego_sorteo_id;

            const DateTime = this.$luxon;
            // Obtén la fecha y hora actual
            let ahora = DateTime.fromFormat(dateParam, 'yyyy-LL-dd').setZone('America/Caracas');
            // Encuentra el lunes de la semana actual
            let lunes = ahora.startOf('week');
            // Asegúrate de que la hora esté seteada en 00:00:00
            lunes = lunes.set({ hour: 0, minute: 0, second: 0 });

            parameters.daterange = [lunes.toISODate(), dateParam];

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.weekSummary = reportList[0];
                }).finally(() => {
                    this.loadingWeekSummary = false;
                });
        },
        open: function (route, filters, context = 'tab') {
            const comercio = filters.comercio;
            delete filters.comercio;
            
            const query = {
                ... this.params,
                ... filters,
            };
            
            if (comercio) query[`${comercio.comercio_type}_id`] = comercio.comercio_id;
            
            const $route = this.$router.resolve({
                name: route,
                query: query,
            });

            if (context == 'tab') return window.open(new URL($route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL($route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        }
    },
    watch: { 
        'params': function () {
            this.getList();
            this.getDaySummary();
            this.getWeekSummary();
        },
        'divisa': function () {
            this.getList();
            this.getDaySummary();
            this.getWeekSummary();
        }
    },
    mounted: function () {
        this.getList();
        this.getDaySummary();
        this.getWeekSummary();
    }
}
</script>
<style>

</style>