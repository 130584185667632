<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center mb-3">
            <div class="mr-3">
                <DateInput v-model="fields.date" :max="$luxon.now().plus({days: 1}).toISODate()" @change="changed('date', $event)" label="Fechas"/>
            </div>
        </div>
        <template v-if="context == 'comercio'">
            <div class="d-flex align-center mb-3">
                <div class="mr-3">
                    <v-select
                        :items="masterList"
                        :loading="isLoadingMasterList"
                        :disabled="isLoadingMasterList"
                        item-text="name"
                        item-value="id"
                        label="Master"
                        @change="changed('master_id', $event)"
                        v-model="fields.master_id"
                        hide-details
                        filled
                        >
                    </v-select>
                </div>
                <div class="mr-3">
                    <v-select
                        :items="comercializadoraList"
                        :loading="isLoadingComercializadoraList"
                        :disabled="isLoadingComercializadoraList || !this.fields.master_id"
                        item-text="name"
                        item-value="id"
                        label="Comercializadora"
                        @change="changed('comercializadora_id', $event)"
                        v-model="fields.comercializadora_id"
                        hide-details
                        filled
                        />
                </div>
                <div class="mr-3">
                    <v-select
                        :items="grupoList"
                        :loading="isLoadingGrupoList"
                        :disabled="isLoadingGrupoList || !this.fields.comercializadora_id"
                        item-text="name"
                        item-value="id"
                        label="Grupo"
                        @change="changed('grupo_id', $event)"
                        v-model="fields.grupo_id"
                        hide-details
                        filled
                        />
                </div>
                <div class="mr-3">
                    <v-select
                        :items="taquillaList"
                        :loading="isLoadingTaquillaList"
                        :disabled="isLoadingTaquillaList || !this.fields.grupo_id"
                        item-text="name"
                        item-value="id"
                        label="Taquilla"
                        @change="changed('taquilla_id', $event)"
                        v-model="fields.taquilla_id"
                        hide-details
                        filled
                        />
                </div>
            </div>
        </template>
        <div class="d-flex align-center mb-3">
            <template v-if="context == 'sistema'">
                <div class="mr-3">
                    <SelectorSistema 
                        v-model="fields.sistema_id"
                        :params="{ sistema_type_slug: sistema_type }"
                        :disabled="loading"
                        />
                </div>
            </template>
            <div class="mr-3">
                <v-select
                    :items="juegoList"
                    :loading="isLoadingJuegoList"
                    :disabled="isLoadingJuegoList"
                    item-text="name"
                    item-value="id"
                    label="Juego"
                    @change="changed('juego_id', $event)"
                    v-model="fields.juego_id"
                    hide-details
                    filled
                    >
                </v-select>
            </div>
            <div class="mr-3">
                <v-select
                    :items="juegoSorteoList"
                    :loading="isLoadingJuegoSorteoList"
                    :disabled="isLoadingJuegoSorteoList || !this.fields.juego_id"
                    item-text="horario"
                    item-value="id"
                    label="Sorteo"
                    @change="changed('juego_sorteo_id', $event)"
                    v-model="fields.juego_sorteo_id"
                    hide-details
                    filled
                    />
            </div>
            <div>
                <SelectorNumero v-model="fields.numero" :disabled="!this.fields.juego_id" :params="{juego_id: this.fields.juego_id}" filled/>
            </div>
        </div>
        <v-divider class="my-4" />
        <div class="d-flex align-center">
            <div>
                <div class="mb-1">
                    <v-checkbox 
                        v-model="fields.consolidado" 
                        label="Consolidar Por Divisa"
                        hide-details
                        class="ma-0"
                        dense
                        ></v-checkbox>
                </div>
                <div v-if="fields.consolidado">
                    <SelectorDivisaChip v-model="fields.divisaList"/>
                </div>
            </div>
            <v-spacer/>
            <div v-if="context == 'comercio' && fields.numero">
                <v-select
                    class="mr-3"
                    label="Agrupar por comercio"
                    :items="groupByOptions"
                    @change="changed('comercio_to_group', $event)"
                    v-model="fields.comercio_to_group"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    filled
                    />
            </div>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';

import MasterService from '@services/comercio/MasterService';
import ComercializadoraService from '@services/comercio/ComercializadoraService';
import GrupoService from '@services/comercio/GrupoService';
import TaquillaService from '@services/comercio/TaquillaService';

import JuegoService from '@services/loteria/JuegoService';
import JuegoSorteoService from '@services/loteria/JuegoSorteoService';

export default {
    components: {
    },
    props: {
        context: { default: 'comercio' },
        value: {},
        loading: {},
        juego: {},
        sistema_type: { default: 'sistema' }
    },
    mixins: [Search],
    data: function ()  {
        return {
            juegoList: [],
            isLoadingJuegoList: false,

            juegoSorteoList: [],
            isLoadingJuegoSorteoList: false,

            masterList: [],
            isLoadingMasterList: false,

            comercializadoraList: [],
            isLoadingComercializadoraList: false,

            grupoList: [],
            isLoadingGrupoList: false,

            taquillaList: [],
            isLoadingTaquillaList: false,

            defaultFields: {
                date: this.$luxon.local().toISODate(),

                master_id: null,
                comercializadora_id: null,
                grupo_id: null,
                taquilla_id: null,
                sistema_id: null,

                juego_id: null,
                juego_sorteo_id: null,

                numero: null,

                consolidado: false,
                divisaList: [],

                comercio_to_group: null,
            },
        }
    },
    computed: {
        defaultJuegoSlug: function () {
            return this.$store.getters['persist/default/defaultJuegoSlug'];
        },
        groupByOptions: function () {
            const list = [
                {
                    id: 'comercializadora',
                    name: 'Comercializadora'
                },
                {
                    id: 'grupo',
                    name: 'Grupo'
                },
                {
                    id: 'taquilla',
                    name: 'Taquilla'
                },
            ];

            const filter = ['taquilla', 'grupo', 'comercializadora'].find(filter => this.fields[`${filter}_id`]);

            if (filter) {
                for (const comercio of ['comercializadora', 'grupo', 'taquilla']) {
                    const index = list.findIndex((item => item.id == comercio));

                    if (index == -1) throw new Error('Comercio No Configurado');
                    
                    list.splice(index, 1);

                    if (filter == comercio) break;
                }
            }
            
            return [
                {
                    id: null,
                    name: 'Comercio Filtrado'
                },
                ... list,
            ];
        }
    },
    created: function () {
        this.getMasterList();
        this.getJuegoList();
    },
    mounted: function () {
        this.preloadLists();
    },
    methods: {
        afterSearch: function () {
            if (this.fields.juego_id && !this.$lodash.isEqual(this.juego?.id, this.fields.juego_id)) this.$emit('update:juego', this.juegoList.find(juego => juego.id == this.fields.juego_id));
        },
        getMasterList: function () {
            this.masterList = [];
            this.isLoadingMasterList = true;
            MasterService.getList()
                .then(({ masterList }) => {
                    this.masterList = [
                        {
                            name: 'Todos',
                            id: null,
                        },
                        ...masterList
                    ];
                })
                .finally(() => this.isLoadingMasterList = false);
        },
        getComercializadoraList: function (parent_id = null) {
            const master_id = this.fields.master_id || parent_id;
            this.comercializadoraList = [];
            if (!master_id) return;
            this.isLoadingComercializadoraList = true;
            ComercializadoraService.getList({ master_id })
                .then(({ comercializadoraList }) => {
                    this.comercializadoraList = [
                        {
                            name: 'Todos',
                            id: null,
                        },
                        ...comercializadoraList
                    ];
                })
                .finally(() => this.isLoadingComercializadoraList = false);
        },
        getGrupoList: function (parent_id = null) {
            const comercializadora_id = this.fields.comercializadora_id || parent_id
            this.grupoList = [];
            if (!comercializadora_id) return;
            this.isLoadingGrupoList = true;
            GrupoService.getList({ comercializadora_id })
                .then(({ grupoList }) => {
                    this.grupoList = [
                        {
                            name: 'Todos',
                            id: null,
                        },
                        ...grupoList
                    ];
                })
                .finally(() => this.isLoadingGrupoList = false);
        },
        getTaquillaList: function (parent_id = null) {
            const grupo_id = this.fields.grupo_id || parent_id
            this.taquillaList = [];
            if (!grupo_id) return;
            this.isLoadingTaquillaList = true;
            TaquillaService.getList({ grupo_id })
                .then(({ taquillaList }) => {
                    this.taquillaList = [
                        {
                            name: 'Todos',
                            id: null,
                        },
                        ...taquillaList
                    ];
                })
                .finally(() => this.isLoadingTaquillaList = false);
        },

        getJuegoList: function () {
            this.juegoList = [];
            this.isLoadingJuegoList = true;
            JuegoService.getList()
                .then(({ juegoList }) => {
                    this.juegoList = [
                        {
                            name: 'Todos',
                            id: null,
                        },
                        ...juegoList
                    ];
                    if (!this.fields.juego_id) this.fields.juego_id = this.juegoList.find((juego) => juego.slug == this.defaultJuegoSlug)?.id;
                    if (this.fields.juego_id) this.$emit('update:juego', this.juegoList.find(juego => juego.id == this.fields.juego_id));
                })
                .finally(() => this.isLoadingJuegoList = false);
        },
        getJuegoSorteoList: function (parent_id = null) {
            const juego_id = this.fields.juego_id || parent_id
            this.juegoSorteoList = [];
            if (!juego_id) return;
            this.isLoadingJuegoSorteoList = true;
            JuegoSorteoService.getList({ juego_id })
                .then(({ juegoSorteoList }) => {
                    this.juegoSorteoList = [
                        {
                            horario: 'Todos',
                            id: null,
                        },
                        ...juegoSorteoList
                    ];
                    const hora = this.$luxon.now();
                    if (!this.fields.juego_sorteo_id) this.fields.juego_sorteo_id = juegoSorteoList.find((juegoSorteo) => hora <= this.$luxon.fromFormat(juegoSorteo.horario, 'HH:mm:ss'))?.id;
                    if (this.fields.juego_sorteo_id) {
                        this.changed('juego_sorteo_id', this.fields.juego_sorteo_id);
                        this.search()
                    }
                })
                .finally(() => this.isLoadingJuegoSorteoList = false);
        },
        preloadLists: function () {
            const defaults = this.default;
            if (defaults.master_id) {
                this.getComercializadoraList();
            }
            if (defaults.comercializadora_id) {
                this.getGrupoList();
            }
            if (defaults.grupo_id) {
                this.getTaquillaList();
            }
            if (defaults.juego_id) {
                this.getJuegoSorteoList();
            }
        },
        onChange: function (field) {
            switch(field) {
                case 'master_id':
                    this.fields.comercializadora_id = null;   
                break;
                case 'comercializadora_id':
                    this.fields.grupo_id = null;   
                break;
                case 'grupo_id':
                    this.fields.taquilla_id = null;   
                break;
                case 'juego_id':
                    this.fields.juego_sorteo_id = null;
                break;
            }
        }
    },
    watch: {
        'fields.juego_id': function (juego_id) {
            if (juego_id !== null) this.getJuegoSorteoList();
        },
        'fields.master_id': function (master_id) {
            this.comercio_to_group = null;
            if (master_id !== null) this.getComercializadoraList();
        },
        'fields.comercializadora_id': function (comercializadora_id) {
            this.comercio_to_group = null;
            if (comercializadora_id !== null) this.getGrupoList(); 
        },
        'fields.grupo_id': function (grupo_id) {
            this.comercio_to_group = null;
            if (grupo_id !== null) this.getTaquillaList();
        },
        'fields.taquilla_id': function () {
            this.comercio_to_group = null;
        },
        'fields.numero': function (numero, old) {
            if (numero === null) this.comercio_to_group = null;
            if (old === null && numero) this.comercio_to_group = null;
        }
    }
}
</script>

<style>

</style>