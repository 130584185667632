<template>
    <v-dialog
        v-model="dialog"
        width="600"
        persistent
    >
        <v-card>
            <v-card-title class="primary white--text">
                <h3>Agregar Resultado</h3>
                <v-spacer/>
                <v-btn icon dark @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="pa-3">
                <JuegoResultadoForm :loading="loading" :juego="juego" v-model="juegoResultadoData" :valid.sync="juegoResultadoValid"/>
                <!--ul v-if="errors">
                    <p v-for="(messages, index) in errors" :key="index">
                        <v-alert dismissible type="error">{{ messages }}</v-alert>
                    </p>
                </ul-->
                <ul>
                    <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
                </ul>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    text 
                    :disabled="loading"
                    @click="reset()"
                    >
                    RESETEAR
                </v-btn>
                <v-btn 
                    color="primary" 
                    :disabled="!juegoResultadoValid || loading"
                    :loading="loading"
                    @click="submit()"
                    >
                    <v-icon>mdi-content-save</v-icon>
                    AÑADIR RESULTADO
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import JuegoResultadoForm from "./form"

import JuegoResultadoService from '@services/operadora/loteria/JuegoResultadoService';

export default {
    components: {
        JuegoResultadoForm,
    },
    props: ['juego', 'juegoResultado', 'open'],
    data: () => ({
        loading: false,
        dialog: false,
        juegoResultadoData: {},
        juegoResultadoValid: false,
        errors:[],
    }),
    methods: {
        closeDialog: function () {
            this.dialog = false;
        },
        submit: function () {
            this.loading = true;
            let action = null;
            
            if (this.juegoResultado) action = JuegoResultadoService.update(this.juegoResultado.id, this.juegoResultadoData);
            else action = JuegoResultadoService.create(this.juegoResultadoData);

            action.then(({ juegoResultado }) => {
                this.emit(juegoResultado);
                this.$ui.notificate({message: 'Resultado Registrado', color: 'success'});
                this.closeDialog();
            }).catch((error) => {
                this.errors = error.response.data.data.messages;
                this.$ui.notificate({message: 'Error de validación', color: 'error'});
            })
            .finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            this.juegoResultadoData = {};
            this.juegoResultadoData['juego_id'] = this.juego?.id;
            this.juegoResultadoData['loteria_id'] = this.juego?.loteria_id;
        }
    },
    watch: {
        juegoResultado: function (value) {
            this.juegoResultadoData = value;
        },
        open: function (open) {
            if (open) {
                this.reset();
                this.dialog = true;
            }
        },
        dialog: function () {
            if (!this.dialog) this.$emit('update:open', false);
        },
    }
}
</script>
