<template>
    <v-container fluid>
        <v-row>
            <v-col cols="8">
                <v-card>
                    <v-card-title>
                        <span>Resultados</span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <JuegoResultadoSearch v-model="params" :loading="loading" @search="getJuegoResultadoList()"/>
                        <br/>
                        <v-divider/>
                        <br/>
                        <JuegoResultado 
                            v-for="(juegoResultado, index) of juegoResultadoList" 
                            :key="`juego-resultado-${juegoResultado.id}-${index}`" 
                            :juegoResultado="juegoResultado"
                            />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card>
                    <v-card-title>
                        <span>Proveedores</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import JuegoResultadoService from '@services/operadora/loteria/JuegoResultadoService';
import JuegoResultadoSearch from '@components/entities/loteria/juego-resultado/search';

import JuegoResultado from './components/resultado.vue';

export default {
    components: {
        JuegoResultadoSearch,
        JuegoResultado
    },
    data: function () {
        return {

            options: {},
            loading: false,
            juegoResultadoList: [],
            juegoResultadoCount: 0,

            params: {
                is_anulado: false,
                fecha: this.$luxon.local().toISODate()
            }
        }
    },
    computed: {
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Resultados');
    },
    methods: {
        getJuegoResultadoList: function (params = {}) {
            this.loading = true;
            if(!Object.hasOwn(params,'limit')) params = {...params, ...this.getOptions()};
            JuegoResultadoService.getList({...this.params, ...params})
                .then(({juegoResultadoList, juegoResultadoCount}) => {
                    this.juegoResultadoList = juegoResultadoList;//this.$lodash.sortBy(juegoResultadoList, ['juego.name'], ['asc']);
                    this.juegoResultadoCount = juegoResultadoCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getOptions: function () {
            const params = {};
            let itemsPerPage = null;
            if (this.options.itemsPerPage !== -1) itemsPerPage = this.options.itemsPerPage
            else if (this.count) itemsPerPage = this.juegoResultadoCount
            params['limit'] = itemsPerPage;
            params['offset'] = (this.options.page * itemsPerPage) - itemsPerPage;
            return params;
        },
    },
    watch: {
      options: {
        handler: function () {
            this.getJuegoResultadoList(this.getOptions());
        },
        deep: true,
      },
    },
}
</script>

<style>

</style>