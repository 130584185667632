<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <MasterCard :master="master" :loading="loading">
                    <template v-slot:actions>
                        <v-btn color="primary" @click="edit()">
                            <v-icon>mdi-pencil</v-icon> EDITAR
                        </v-btn>
                    </template>
                    <v-tabs v-model="tab" icons-and-text>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-cupos" v-if="master && $user.isDomain('operadora')">JUEGOS <v-icon>mdi-account-box</v-icon></v-tab>
                        <!-- <v-tab href="#tab-usuarios" v-if="master && $user.isDomain(['operadora', 'master'])">Usuarios <v-icon>mdi-account-box</v-icon></v-tab> -->
                    </v-tabs>
                </MasterCard>
            </v-col>
            <v-col cols="12">
                <v-card elevation="5">
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-cupos" v-if="master && ($user.isDomain('operadora'))">
                            <v-card flat>
                                <v-card-text>
                                    <MasterCupoCrud :master="master"/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-usuarios" v-if="master && $user.isDomain(['operadora', 'master'])">
                            <v-card flat>
                                <v-card-title>
                                    Usuarios
                                </v-card-title>
                                <v-card-text>
                                    <UserCrud comercioType="master" :comercioId="master.id"/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <MasterModal :open.sync="isModalOpen" @saved="getMaster()" :master="master"/>
    </v-container>
</template>
<script>
import MasterCard from '@components/entities/comercio/master/card';
import MasterModal from '@components/entities/comercio/master/modal';

import MasterService from '@services/comercio/MasterService';

import MasterCupoCrud from '@components/entities/comercio/master/juego/crud';
import UserCrud from '@components/entities/comercio/user/crud';

export default {
    components: {
        MasterCard,
        MasterModal,
        MasterCupoCrud,
        UserCrud
    },
    data: () => ({
        tab: null,
        master: null,
        loading: false,

        isModalOpen: false,
    }),
    created: function () {
        this.getMaster();
    },
    methods: {
        getMaster: function () {
            this.loading = true;
            MasterService
                .get(this.$route.params.id)
                .then(({master}) => {
                    this.master = master;
                    console.log(master);
                    this.$store.dispatch('ui/layout/setTitle', `Master ${this.master?.name}`);
                })
                .finally(() => this.loading = false);
        },
        edit: function () {
            this.isModalOpen = true;
        },
    },
    watch: {
        'comercializadora': function (comercializadora) {
            if (comercializadora) this.$store.dispatch('ui/layout/setTitle', `Comercializadora ${this.comercializadora?.name}`);
        },
    }
}
</script>