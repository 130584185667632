<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center mb-3">
            <div class="mr-3 mb-3">
                <DateRangeInput v-model="fields.daterange" @change="changed('daterange', $event)" label="Rango de Fechas"/>
            </div>
            <div class="mr-3 mb-3">
                <SelectorJuego v-model="fields.juego_id" @change="changed('juego_id', $event)" filled/>
            </div>
            <div class="mr-3 mb-3">
                <SelectorDivisa v-model="fields.divisa_id" @change="changed('divisa_id', $event)"/>
            </div>
        </div>
        <v-divider class="my-4" />
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';

export default {
    components: {
        
    },
    props: ['value', 'loading'],
    mixins: [Search],
    data: function ()  {
        return {
            defaultFields: {
                daterange: [this.$luxon.local().toISODate(), this.$luxon.local().toISODate()],
                juego_id: null,
                divisa_id: null,
            },
        }
    }
}
</script>

<style>

</style>