<template>
    <div>
        <div class="mb-2">
            <v-card>
                <v-card-title>
                    Ventas Por Figura
                </v-card-title>
                <v-card-text>
                    <div class="d-flex align-center mb-2" v-if="daySummary">
                        <span class="mr-4">DÍA</span>
                        <Totalizador :loading="loadingDaySummary" :summary="daySummary"/>
                    </div>
                    <div class="d-flex align-center" v-if="params.juego_sorteo_id && weekSummary">
                        <span class="mr-4">SEMANA</span>
                        <Totalizador :loading="loadingWeekSummary" :summary="weekSummary"/>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-simple-table>
            <template v-slot:default>
                <template v-if="loading">
                    <thead>
                        <tr>
                            <th>Comercio</th>
                            <th>Tickets</th>
                            <th>1 Acierto</th>
                            <th>2 Aciertos</th>
                            <th>Ganadores</th>
                            <th>Comision</th>
                            <th>Proyección</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="9">Cargando...</td>
                        </tr>
                    </tbody>
                </template>
                <template v-else>
                    <thead>
                        <tr>
                            <th>Comercio</th>
                            <th>Tickets</th>
                            <th>1 Acierto</th>
                            <th>2 Aciertos</th>
                            <th>Ganadores</th>
                            <th>Proyección</th>
                            <th>Total Día</th>
                            <th>Total Semana</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(report, index) of list" :key="`reporte-figura-tripleta-${index}`">
                            <td>
                                <v-btn v-if="context == 'comercio'" class="mr-2" text small @click="open({
                                    'comercio': 'reporte.figura',
                                    'sistema': 'operadora.sistema.reporte.figura',
                                }[context], {
                                    comercio: {
                                        type: report.comercio_type,
                                        id: report.comercio_id,
                                    }
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-eye-outline</v-icon>
                                    {{ report.comercio }} 
                                </v-btn>
                                <template v-else>
                                    {{ report.comercio }} 
                                </template>
                            </td>
                            <td>
                                <v-chip label color="primary" @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    numero: params.numero,
                                    comercio: {
                                        type: report.comercio_type,
                                        id: report.comercio_id,
                                    }
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.count }}</span>
                                    <span class="ml-2">{{ report.venta_total | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip label @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    projected_one_hit: params.numero,
                                    comercio: {
                                        type: report.comercio_type,
                                        id: report.comercio_id,
                                    }
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.one_hit_count }}</span>
                                    <span class="ml-2">{{ report.one_hit_venta | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip label @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    projected_two_hit: params.numero,
                                    comercio: {
                                        type: report.comercio_type,
                                        id: report.comercio_id,
                                    }
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.two_hit_count }}</span>
                                    <span class="ml-2">{{ report.two_hit_venta | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip label color="amber darken-4" @click="open({
                                    'comercio': 'tickets',
                                    'sistema': 'operadora.sistema.tickets',
                                }[context], {
                                    juego_id: params.juego_id,
                                    juego_sorteo_id: params.juego_sorteo_id,
                                    projected_three_hit: params.numero,
                                    comercio: {
                                        type: report.comercio_type,
                                        id: report.comercio_id,
                                    }
                                })">
                                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                    <span>{{ report.winner_count }}</span>
                                    <span class="ml-2">{{ report.winner_venta | currency(report.code) }}</span>
                                </v-chip>
                            </td>
                            <td>{{ report.projected | currency(report.code) }}</td>
                            <td>
                                <v-chip small label :color="report.percent_projected_per_day > report.comision_percent ? 'error': 'primary'" class="font-weight-bold mr-2">
                                    {{ report.total_projected_per_day | currency(report.divisa_code) }} {{' / '}} {{ report.percent_projected_per_day | percent }}
                                </v-chip>
                            </td>
                            <td>
                                <v-chip small label :color="report.percent_projected_per_week > report.comision_percent ? 'error': 'primary'" class="font-weight-bold mr-2">
                                    {{ report.total_projected_per_week | currency(report.divisa_code) }} {{' / '}} {{ report.percent_projected_per_week | percent }}
                                </v-chip>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </template>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import TripletaService from '@services/reporte/game/TripletaService';
import VentaService from '@services/reporte/VentaService';

import Totalizador from '@components/entities/reporte/venta/figura/totalizador';

export default {
    components: {
        Totalizador
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        context: {
            default: 'comercio',
        },
    },
    data: function() { 
        return {
            list: [],
            count: 0,
            loading: true,  
            loadingDaySummary: true,
            daySummary: null,
            loadingWeekSummary: true,
            weekSummary: null,
        }
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        listComputed: function () {
            return this.list.map(report => {
                return {
                    ...report,
                }
            })
        },
    },
    methods: {
        getLegibleDistanciaFecha: function (date) {
            if (!date) return 0;

            const start = this.$luxon.fromSQL(date);
            const end = this.$luxon.now();

            const days = Math.floor(end.diff(start, 'days').toObject().days);

            return days;
        },
        getList: function (params = {}) {
            this.list = [];
            this.loading = true;

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            TripletaService.reportePorFiguraDetallado({...this.params, ...params, divisa_id: this.divisa.id})
                .then(({reportList}) => {
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getDaySummary: function (params = {}) {
            this.daySummary = null;
            this.loadingDaySummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};

            delete parameters.juego_sorteo_id;

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.daySummary = reportList[0];
                }).finally(() => {
                    this.loadingDaySummary = false;
                });
        },
        getWeekSummary: function (params = {}) {
            this.weekSummary = null;
            this.loadingWeekSummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};
            
            const dateParam = parameters.date;
            delete parameters.date;
            delete parameters.juego_sorteo_id;

            const DateTime = this.$luxon;
            // Obtén la fecha y hora actual
            let ahora = DateTime.fromFormat(dateParam, 'yyyy-LL-dd').setZone('America/Caracas');
            // Encuentra el lunes de la semana actual
            let lunes = ahora.startOf('week');
            // Asegúrate de que la hora esté seteada en 00:00:00
            lunes = lunes.set({ hour: 0, minute: 0, second: 0 });

            parameters.daterange = [lunes.toISODate(), dateParam];

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.weekSummary = reportList[0];
                }).finally(() => {
                    this.loadingWeekSummary = false;
                });
        },
        open: function (route, filters, context = 'tab') {
            const comercio = filters.comercio;
            delete filters.comercio;
            
            const query = {
                ... this.params,
                ... filters,
            };
            
            if (comercio) query[`${comercio.type}_id`] = comercio.id;

            const $route = this.$router.resolve({
                name: route,
                query: query,
            });

            if (context == 'tab') return window.open(new URL($route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL($route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        }
    },
    watch: { 
        'params': function () {
            this.getList();
            this.getDaySummary();
            this.getWeekSummary();
        },
        'divisa': function () {
            this.getList();
            this.getDaySummary();
            this.getWeekSummary();
        }
    },
    mounted: function () {
        this.getList();
        this.getDaySummary();
        this.getWeekSummary();
    }
}
</script>
<style>

</style>