import axios from '@plugins/axios';
import CrudService from "@utils/CrudService";

class JuegoResultadoService extends CrudService {
    anular(id, options = {}) {
        return axios({
            url: this.getRoute(`${id}/anular`),
            method: 'POST',
            ...options
        });
    }
}

export const BaseJuegoResultadoService = JuegoResultadoService

export default new JuegoResultadoService({
    domainName:'operadora/loteria',
    entityName:'juego-resultado',
    fields: ['resultado', 'juego_sorteo_id', 'fecha', 'juego_id', 'loteria_id', 'metadata'],
    query:['loteria_id', 'juego_id', 'juego_sorteo_id', 'is_anulado', 'is_anulado_at', 'fecha', 'limit', 'offset'],
});