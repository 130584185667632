var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('v-card',[_c('v-card-title',[_vm._v(" Ventas Por Figura ")]),_c('v-card-text',[(_vm.daySummary)?_c('div',{staticClass:"d-flex align-center mb-2"},[_c('span',{staticClass:"mr-4"},[_vm._v("DÍA")]),_c('Totalizador',{attrs:{"loading":_vm.loadingDaySummary,"summary":_vm.daySummary}})],1):_vm._e(),(_vm.params.juego_sorteo_id && _vm.weekSummary)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-4"},[_vm._v("SEMANA")]),_c('Totalizador',{attrs:{"loading":_vm.loadingWeekSummary,"summary":_vm.weekSummary}})],1):_vm._e()])],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.loading)?[_c('thead',[_c('tr',[_c('th',[_vm._v("Comercio")]),_c('th',[_vm._v("Tickets")]),_c('th',[_vm._v("1 Acierto")]),_c('th',[_vm._v("2 Aciertos")]),_c('th',[_vm._v("Ganadores")]),_c('th',[_vm._v("Comision")]),_c('th',[_vm._v("Proyección")]),_c('th',[_vm._v("Total")]),_c('th')])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"9"}},[_vm._v("Cargando...")])])])]:[_c('thead',[_c('tr',[_c('th',[_vm._v("Comercio")]),_c('th',[_vm._v("Tickets")]),_c('th',[_vm._v("1 Acierto")]),_c('th',[_vm._v("2 Aciertos")]),_c('th',[_vm._v("Ganadores")]),_c('th',[_vm._v("Proyección")]),_c('th',[_vm._v("Total Día")]),_c('th',[_vm._v("Total Semana")]),_c('th')])]),_c('tbody',_vm._l((_vm.list),function(report,index){return _c('tr',{key:`reporte-figura-tripleta-${index}`},[_c('td',[(_vm.context == 'comercio')?_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.open({
                                'comercio': 'reporte.figura',
                                'sistema': 'operadora.sistema.reporte.figura',
                            }[_vm.context], {
                                comercio: {
                                    type: report.comercio_type,
                                    id: report.comercio_id,
                                }
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-eye-outline")]),_vm._v(" "+_vm._s(report.comercio)+" ")],1):[_vm._v(" "+_vm._s(report.comercio)+" ")]],2),_c('td',[_c('v-chip',{attrs:{"label":"","color":"primary"},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                numero: _vm.params.numero,
                                comercio: {
                                    type: report.comercio_type,
                                    id: report.comercio_id,
                                }
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.venta_total,report.code)))])],1)],1),_c('td',[_c('v-chip',{attrs:{"label":""},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                projected_one_hit: _vm.params.numero,
                                comercio: {
                                    type: report.comercio_type,
                                    id: report.comercio_id,
                                }
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.one_hit_count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.one_hit_venta,report.code)))])],1)],1),_c('td',[_c('v-chip',{attrs:{"label":""},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                projected_two_hit: _vm.params.numero,
                                comercio: {
                                    type: report.comercio_type,
                                    id: report.comercio_id,
                                }
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.two_hit_count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.two_hit_venta,report.code)))])],1)],1),_c('td',[_c('v-chip',{attrs:{"label":"","color":"amber darken-4"},on:{"click":function($event){return _vm.open({
                                'comercio': 'tickets',
                                'sistema': 'operadora.sistema.tickets',
                            }[_vm.context], {
                                juego_id: _vm.params.juego_id,
                                juego_sorteo_id: _vm.params.juego_sorteo_id,
                                projected_three_hit: _vm.params.numero,
                                comercio: {
                                    type: report.comercio_type,
                                    id: report.comercio_id,
                                }
                            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_c('span',[_vm._v(_vm._s(report.winner_count))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("currency")(report.winner_venta,report.code)))])],1)],1),_c('td',[_vm._v(_vm._s(_vm._f("currency")(report.projected,report.code)))]),_c('td',[_c('v-chip',{staticClass:"font-weight-bold mr-2",attrs:{"small":"","label":"","color":report.percent_projected_per_day > report.comision_percent ? 'error': 'primary'}},[_vm._v(" "+_vm._s(_vm._f("currency")(report.total_projected_per_day,report.divisa_code))+" "+_vm._s(' / ')+" "+_vm._s(_vm._f("percent")(report.percent_projected_per_day))+" ")])],1),_c('td',[_c('v-chip',{staticClass:"font-weight-bold mr-2",attrs:{"small":"","label":"","color":report.percent_projected_per_week > report.comision_percent ? 'error': 'primary'}},[_vm._v(" "+_vm._s(_vm._f("currency")(report.total_projected_per_week,report.divisa_code))+" "+_vm._s(' / ')+" "+_vm._s(_vm._f("percent")(report.percent_projected_per_week))+" ")])],1),_c('td')])}),0)]]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }