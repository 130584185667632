<template>
    <v-card outlined>
        <v-card-text>
            <div class="d-flex align-center">
                <div>
                    <div class="text-h6">
                        <span>{{ juegoResultado.juego.name }} - {{ juegoResultado.juego_sorteo.horario }}</span>
                    </div>
                    <div>
                        
                    </div>
                </div>
                <div>
                    <v-card flat>
                        <v-card-text>
                            <span class="text-h3">{{ juegoResultado.resultado }}</span>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        juegoResultado: {
            type: Object,
        }
    },
    data() {
        return {
            text: 'This is a test card.'
        }
    }
}
</script>